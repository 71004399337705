import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://ac4851640df849e78f86ee7091a4479a@sentry-rnd-prod.bbrp.co/65",
  debug: false,
  environment: "prod",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});